@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"



































.lecture-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 32px
  padding: 12px 24px

  +mq-l
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 24px

  +mq-s
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 16px
