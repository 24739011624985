@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"































.wrapper-title
  display: flex

  .feather
    overflow: initial

  &__text
    padding-left: 10px

  &__label
    font-size: 20px
    color: $color-main

  &__description
    font-size: 16px
    color: $color-ink-light
