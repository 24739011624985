@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"































































































































































































































.channels-list
  background: $color-ice
  position: relative

  &:after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 4px
    background: $color-main
    border-radius: 10px 10px 0 0

  &__full__height
    min-height: calc(100vh - 176px)

  & ::v-deep .wrapper-title
    padding: 30px 60px

    +mq-s
      padding: 24px

  &__swiper
    position: relative

  .swiper-container
    padding: 0 0 30px
    max-width: calc(100% - 200px)

    +mq-s
      padding: 0 24px 30px
      max-width: 100%

  .swiper-slide
    +mq-s
      width: 90%

  .channels-list__slide
    min-height: 110px
    border-radius: 9px
    background: $color-primary
    padding: 13px
    cursor: pointer
    box-shadow: 0 1px 3px rgba(0,0,0,.25)

    &:hover
      +anim(200ms)
      box-shadow: 0 6px 12px rgba(0,0,0,.35)

    *
      color: white

    &__header
      margin-bottom: 20px
      +flex_sb

      &__icon
        display: flex
        align-items: center

      &__text
        text-transform: uppercase
        padding-left: 5px
        font-size: 10px

    &__navigation
      position: absolute
      top: calc(50% - 33px)
      z-index: 9
      cursor: pointer
      outline: none
      width: 45px
      height: 45px
      display: flex
      align-items: center
      justify-content: center

      +mq-s
        display: none

      &:hover:not(.swiper-button-disabled)
        svg
          path
            +anim(200ms)
            stroke: $color-primary

      &.swiper-button-disabled
        opacity: .4
        cursor: not-allowed
        pointer-event: none

      &.channels-list__slide__prev
        left: 40px

      &.channels-list__slide__next
        right: 40px

        svg
          transform: rotate(180deg)

        &.tour-active
          &:before
            background: white
            width: 55px
            height: 55px

    &__body__title
      font-size: 20px

    &__body__description
      color: $color-primary-lighter
      font-size: 13px
