@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"































































































































.classroom-body
  min-height: 80vh
  padding: 12px 64px
  background: white

  &__header
    position: relative
    padding: 12px 24px
    display: flex
    justify-content: space-between
    align-items: flex-end

    +mq-m
      display: block

    i
      height: 46px
      color: $color-main

    h3
      font-size: 36px
      padding: 8px 0
      color: $color-main

    label
      font-size: 16px
      font-weight: 100

      +mq-s
        max-width: calc(100% - 5px)

  +mq-s
    padding: 0

.channel-body
  background: rgba($color-primary-lightest, .4)
  &.tour-active
    &:before
      background: white

  &__videos-channels
    width: 10%

  &__line
    background: $color-main
    width: 100%
    height: 4px
    border-radius: 5px

.select
  width: 290px
  text-align: center
  text-align-last: center
  padding-bottom: 6px

  +mq-s
    width: 100%
