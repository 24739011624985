@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"

























































































































.content__header
  height: 120px

  +mq-s
    height: 200px

  +mq-xs
    height: 120px

  &__wrapper
    margin: 0 auto
    max-width: 1032px
    height: 100%
    display: flex
    justify-content: space-between

    +mq-l
      max-width: 720px

    +mq-m
      max-width: calc(100% - 48px)

    +mq-s
      max-width: calc(100% - 16px)
      flex-direction: column-reverse

    &__label
      display: flex
      justify-content: space-between
      align-self: flex-end
      height: 100%
      padding: 12px 12px
      box-sizing: border-box

      +mq-s
        align-self: flex-start

      &__back
        display: flex
        align-items: center
        color: white
        cursor: pointer

        +mq-s
          font-size: 13px

      &__name
        font-size: 36px
        color: white

        +mq-s
          font-size: 28px

        +mq-xs
          font-size: 24px

    &__logo
      height: 64px
      align-self: center

      +mq-s
        height: 256px
        max-width: 100%

      +mq-xs
        height: 70px

.small-header
  height: 120px

.small-header-wrapper
  height: 120px

.small-header-wrapper-label
  height: 100%

.content__header__wrapper__label__back
  h5
    font-size: 13px
