@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"














































































































































.channels-list-slide
  background: $color-ice
  position: relative

  &:after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 4px
    background: $color-main
    border-radius: 10px 10px 0 0

  &__full__height
    min-height: calc(100vh - 176px)

  &__swiper
    position: relative

  .swiper-container
    padding: 0 0 30px
    max-width: calc(100% - 200px)

    +mq-s
      padding: 0 24px 30px
      max-width: 100%

  .swiper-slide
    +mq-s
      width: 90%

  .channels-list-slide__slide
    min-height: 110px
    border-radius: 9px
    background: white
    padding: 13px
    cursor: pointer
    box-shadow: 0 1px 3px rgba(0,0,0,.25)

    .skeleton-bone
      height: 18px !important
      width: 90% !important
      left: 5%
      margin: 5px 0

    .skeleton-bone:first-child
      width: 30% !important

    &:hover
      +anim(200ms)
      box-shadow: 0 6px 12px rgba(0,0,0,.35)

    &__navigation
      position: absolute
      top: calc(50% - 33px)
      z-index: 9
      cursor: pointer
      outline: none
      width: 45px
      height: 45px
      display: flex
      align-items: center
      justify-content: center

      +mq-s
        display: none

      &:hover
        svg
          path
            +anim(200ms)
            stroke: $color-primary

      &.channels-list-slide__slide__prev
        left: 40px

      &.channels-list-slide__slide__next
        right: 40px

        svg
          transform: rotate(180deg)
