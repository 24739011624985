@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"


































































.lecture-card
  display: flex
  flex-direction: column
  align-items: flex-start
  border-radius: 8px
  backface-visibility: hidden
  box-shadow: 0 1px 3px rgba(0,0,0,.25)
  background: $color-ink-light
  position: relative
  height: 100%
  max-width: 100%
  padding: 8px 0
  box-sizing: border-box
  cursor: pointer
  +anim(200ms)

  +mq-s
    padding: 20px 0

  &:hover
    +anim(200ms)
    box-shadow: 0 6px 12px rgba(0,0,0,.35)

  &.tour-active
    &:before
      width: 100%
      height: 100%
      border-radius: 8px

  &__header
    height: 100%
    width: 100%
    box-sizing: border-box
    align-items: center
    display: flex

    span
      background: none !important

    .header__badge
      display: inline-flex
      padding: 0 12px

      +mq-s
        padding: 6px 12px 12px 12px

      .badge-logo
        height: 36px

        +mq-s
          height: 32px

    +mq-s
      flex-direction: column !important
      text-align: center !important

  .card__title
    width: 100%
    box-sizing: border-box
    color: white

    h4
      font-size: 18px
      color: white

    +mq-s
      bottom: 20px

    h2
      +mq-m
        font-size: 32px

      +mq-s
        font-size: 28px
