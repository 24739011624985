@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"























.resource-not-found
  display: flex
  align-items: center
  flex-direction: column
  padding: 24px
  text-align: center

  button
    margin-bottom: 20px

    h5
      padding-left: 10px

  &__icon
    height: 38px
    padding: 12px 0
    color: $color-main

  h4
    color: $color-main

  label
    padding: 12px 0

